<template>
  <div class="contact-us">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url(/img/contactus/header-contact.jpg); background-size: cover"
    >
      <div class="viewport">
        <h1>Let's start the conversation</h1>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="content">
        <h3>Send us a message</h3>
        <form @submit.prevent>
          <div class="form-floating mb-3">
            <input
              v-model="name"
              type="text"
              class="form-control"
              :class="nameEmpty ? 'is-invalid' : ''"
              id="floatingInput"
              placeholder="Your name"
              required
              @blur="validateName()"
            />
            <label for="floatingInput">Your Name</label>
            <span>*</span>
            <div class="invalid-feedback">Please enter your name.</div>
          </div>
          <div class="form-floating mb-3">
            <input
              v-model="email"
              type="email"
              class="form-control"
              :class="emailEmpty ? 'is-invalid' : ''"
              @blur="validateEmail()"
              id="email"
              placeholder="Work Email Address"
              required
            />
            <label for="email">Work Email Address</label>
            <span>*</span>
            <div class="invalid-feedback">Please enter your email address.</div>
          </div>
          <div class="form-floating mb-3">
            <input
              v-model="company"
              type="text"
              class="form-control"
              id="company"
              placeholder="Company"
            />
            <label for="company">Company</label>
          </div>
          <div class="form-floating">
            <textarea
              v-model="emailText"
              class="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              style="height: 280px"
              required
              @blur="validateText()"
              :class="textEmpty ? 'is-invalid' : ''"
            ></textarea>
            <label for="floatingTextarea">Message</label>
            <span>*</span>
            <div class="invalid-feedback">Please enter message.</div>
          </div>
          <div class="text-center">
            By using this contact form, I agree to the
            <router-link to="/privacy-policy"> Privacy Policy.</router-link>
          </div>
          <div class="text-center">
            <button @click="sendEmail()" class="btn btn-primary">Submit</button>
          </div>
          <div v-if="isLoading" class="loader d-flex align-items-center justify-content-center">
            <div>
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span>Sending Email Please Wait...</span>
            </div>
          </div>
          <div v-if="success" class="alert alert-success" role="alert">
            Your email has been sent successfully
          </div>
          <div v-if="error" class="alert alert-danger" role="alert">
            There was an error sending your message! Please try again later.
          </div>
        </form>
      </div>
    </div>
    <div class="map d-flex">
      <div
        class="map-col d-flex justify-content-center"
        style="background: url(img/contactus/stockholm.jpg); background-size: cover"
      >
        <div class="map-content">
          <h4>Where you can find us</h4>
          <ul class="list-unstyled">
            <li>
              <h4>Sweden</h4>
              <span @click="(stockholm = true), (karlskrona = false), (sofia = false)"
                >Drottninggatan 86</span
              >
              <span>111 36 Stockholm</span>
            </li>
            <li>
              <span @click="(stockholm = false), (karlskrona = true), (sofia = false)"
                >Campus Gräsvik 8A</span
              >
              <span>371 75 Karlskrona</span>
            </li>
            <li>Tel: +46 767 78 09 62</li>
            <li class="mt-5">
              <h4>Bulgaria</h4>
              <span @click="(stockholm = false), (karlskrona = false), (sofia = true)"
                >Street Prof. Alexander Tanev 11 4th floor</span
              >
              <span>1715 Sofia</span>
            </li>
            <li>Tel: +359 8 798 28 500</li>
          </ul>
        </div>
        <div class="overlay"></div>
      </div>
      <div class="map-col">
        <div class="map-frame">
          <div v-show="stockholm" class="map-source">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2034.8225600115243!2d18.05732251583935!3d59.33591621753679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d670535d027%3A0xc99b86a21445b773!2sDrottninggatan%2086%2C%20111%2036%20Stockholm%2C%20Sweden!5e0!3m2!1sen!2sbg!4v1655799049771!5m2!1sen!2sbg"
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div v-show="karlskrona" class="map-source">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2220.45545365069!2d15.591318415714902!3d56.18380561613523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46566c543bb775fb%3A0x2a0f5561b5c05d50!2sComstream%20AB!5e0!3m2!1sen!2sbg!4v1655799107429!5m2!1sen!2sbg"
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div v-show="sofia" class="map-source">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11742.022230660315!2d23.3828502!3d42.6294412!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x67492f3436effc34!2sComstream%20OOD!5e0!3m2!1sen!2sbg!4v1655798971671!5m2!1sen!2sbg"
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleMixin from '../mixins/titleMixin';
import axios from 'axios';
export default {
  mixins: [titleMixin],
  title: 'Contact Us | Comstream',
  data() {
    return {
      stockholm: true,
      karlskrona: false,
      sofia: false,
      name: '',
      email: '',
      company: '',
      emailText: '',
      isLoading: false,
      error: false,
      success: false,
      nameEmpty: false,
      emailEmpty: false,
      textEmpty: false,
    };
  },
  watch: {
    name: function (newVal) {
      if (newVal.length > 0) {
        this.name = newVal;
        this.nameEmpty = false;
      }
    },
    email: function (newVal) {
      if (newVal.length > 0) {
        this.email = newVal;
        this.emailEmpty = false;
      }
    },
    emailText: function (newVal) {
      if (newVal.length > 0) {
        this.emailText = newVal;
        this.textEmpty = false;
      }
    },
  },
  methods: {
    sendEmail() {
      if (this.email.length > 0 && this.name.length > 0 && this.emailText.length > 0) {
        this.isLoading = true;
        axios
          .post('https://comstream.eu/api/send-email', {
            name: this.name,
            email: this.email,
            company: this.company,
            content: this.emailText,
          })
          .then(() => {
            this.isLoading = false;
            this.success = true;
            this.name = '';
            this.email = '';
            this.company = '';
            this.emailText = '';
          })
          .catch(() => {
            this.error = true;
            this.isLoading = false;
          });
      }
    },
    validateName() {
      if (this.name.length === 0) {
        this.nameEmpty = true;
      }
    },
    validateEmail() {
      if (this.email.length === 0) {
        this.emailEmpty = true;
      }
    },
    validateText() {
      if (this.emailText.length === 0) {
        this.textEmpty = true;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.contact-us {
  .content {
    padding: 70px 0;
    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      margin: 0px 0 70px 0;
    }
    form {
      position: relative;
      width: 720px;
      margin: 0 auto;
      .form-floating {
        position: relative;
        span {
          position: absolute;
          right: 12px;
          top: 12px;
          font-size: 36px;
          color: red;
        }
      }
      .text-center {
        margin: 25px 0;
      }
      .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        .spinner-border {
          width: 4rem;
          height: 4rem;
          color: #a93224 !important;
          margin: 12px auto;
          display: block;
        }
        span {
          display: block;
        }
      }
    }
  }
  .map {
    .map-col {
      width: 50%;
      position: relative;
      padding: 70px 0;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
      }
      .map-content {
        z-index: 1;
        h4 {
          font-size: 34px;
          color: #fff;
          font-weight: 700;
          margin-bottom: 30px;
        }
        .list-unstyled {
          li {
            font-size: 18px;
            color: #fff;
            margin-bottom: 20px;
            h4 {
              font-size: 28px;
              font-weight: 700;
              margin-bottom: 10px;
            }
            span {
              display: block;
              margin-bottom: 6px;
              &:first-of-type {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
      &:nth-of-type(2) {
        padding: 0;
      }
      .map-frame {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        background: #ddd;
        .map-source {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .contact-us {
    .content {
      h3 {
        font-size: 2rem;
      }
      form {
        width: 100%;
      }
    }
    .map {
      display: block !important;
      .map-col {
        width: 100%;
        .map-content {
          padding: 12px;
          h4 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
</style>
